import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import PolicyItem from "../components/policy-item";
import GlobalConstants from "../helpers/constants";
import Axios from "axios";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { GQLQuery, GQLRichTextContent } from "../types";
import { CrystallizeBaseURL } from "../helpers/constants";

const MyQuery = `
  {
    catalogue(path: "/articles/privacy-policy") {
      name
      components {
        name
        content {
          ...PP_richTextContent
        }
      }
      children {
        ...PP_item
      }
    }
  }

  fragment PP_item on Item {
    name
    components {
      name
      type
      content {
        ...PP_singleLineContent
        ...PP_richTextContent
        ...PP_properties
      }
    }
  }

  fragment PP_singleLineContent on SingleLineContent {
    text
  }

  fragment PP_richTextContent on RichTextContent {
    html
  }

  fragment PP_properties on PropertiesTableContent {
    sections {
      ... on PropertiesTableSection {
        title
        properties {
          key
          value
        }
      }
    }
  }
`;

const PrivacyPolicy = (): JSX.Element => {
  const PathObj = GetSimplePathObj(GlobalConstants.PrivacyPolicy);
  const [QueryResult, SetQueryResult] = React.useState({} as GQLQuery);

  Axios.post(CrystallizeBaseURL, {
    query: MyQuery,
  }).then((response) => {
    SetQueryResult(response.data.data as GQLQuery);
  });

  const DescriptionCompContent = GetComponentByName(
    QueryResult.catalogue?.components ?? [],
    GlobalConstants.Brief
  )?.content as GQLRichTextContent;

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryResult.catalogue?.name}</Typography>
        {DescriptionCompContent?.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
      {QueryResult.catalogue?.children.map((node, index) => (
        <Grid item xs={12} key={index}>
          <PolicyItem
            title={GetComponentByName(node.components, GlobalConstants.Title)}
            content={GetComponentByName(
              node.components,
              GlobalConstants.Content
            )}
            properties={GetComponentByName(
              node.components,
              GlobalConstants.Properties
            )}
          />
        </Grid>
      ))}
    </Layout>
  );
};

export default PrivacyPolicy;
